import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, color, wrapper1224 } from '../../utils/style';
import { Link } from 'gatsby';
import ButtonLink from '../shared/ButtonLink';
import FooterMenuGameTypes from '../Menus/FooterMenuGameTypes';
import FooterMenuGameProviders from '../Menus/FooterMenuGameProviders';
//import FooterMenuBlogs from '../Menus/FooterMenuBlogs';
import FooterMenuRegister from '../Menus/FooterMenuRegister';
import FooterMenuOther from '../Menus/FooterMenuOther';
import Zopim from './Zopim';
import Visa from '../../icons/svg/Visa';
import Mastercard from '../../icons/svg/Mastercard';
import Discover from '../../icons/svg/Discover';
import Paypal from '../../icons/svg/Paypal';
import Facebook from '../../icons/svg/FacebookPlain';
import Twitter from '../../icons/svg/TwitterPlain';
import Youtube from '../../icons/svg/YoutubePlain';
import LogoUpd from './LogoSvg';

//import Logo from './Logo';

const FooterBlock = styled('footer')`
  background: rgba(0,0,0,0.25);
  .top-footer-col {
    display: inline-block;
    vertical-align: top;
  }
  .top-footer-left {
    width: 43%;
  }
  .top-footer-right {
    width: 56%;
    text-align: center;
    padding-top: 80px;
    ul { opacity: 0.7; }
  }
  .menu-col {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    padding: 0 10px 20px;
    text-align: left;
  }
  .menu-col-wide {
    width: 40%;
    padding: 0 10px 0 20px;
    ul {
      column-count: 2;
      column-gap: 2px;
    }
  }
  ul {
    margin:20px 0;
    list-style: none;
    li {
      font-size: 14px;
      margin: 0 15px 10px 0;
    }
    a {
        color: ${color.white}
    }
    a:hover, a:active, .active {
       color: ${color.yellow};
       text-decoration: none;
    }
  }
  .h4 {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .logo {
    width: 200px;
    max-width: 90%;
    display: inline-block;
    margin: 5px 0 25px;
  }
  .footer-top {
    padding: 20px 0;
  }
  .social-buttons {
    padding-right: 38px;
    text-align: right;
    a {
      display: inline-block;
      margin-left: 5px;
      &:hover, &:active {
        svg {
          filter: drop-shadow(1px 1px 4px ${color.peach});
        }
      }
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    padding: 0 20px;
    
    .footer-bottom {
      max-width: 87%;
      margin: 0;
    }
    .social-buttons {
      padding: 0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 50px;
    .footer-top {
      position: relative;
      padding-bottom: 60px;
      .footer-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 100%;
        width: 100%;
      }
    }
    .top-footer-left {
      width: 100%;
    }
    .top-footer-right {
      padding-top: 15px;
    }
    .logo {
      display: none;
    } 
    .top-footer-left, .top-footer-right {
      width: 100%;
      text-align: center;
    }
    .menu-col:nth-of-type(odd) {
      padding-left: 2px;
    }
    .menu-col:nth-of-type(4) {
      padding-right: 0;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .footer-top {
      padding-bottom: 110px;
      .footer-bottom {
        padding: 10px;
      }
    }
    .social-buttons {
      padding: 0;
      text-align: center;
    }
    .menu-col {
      width: 50%;
    }
    .menu-col-wide {
      padding: 0 0 0 10px;
      ul {
        column-count: 1;
        column-gap: 0;
      }
    }
  }
`;

const Wrapper = styled('div')`
  ${wrapper1224}
`;

const Copy = styled('div')`
   float: left;
   width: 45%;
   font-size: 14px;
   line-height: 1.2;
   text-align: left;
   span {
     opacity: 0.7;
   } 
   a { 
     margin: 0 0 0 20px;
     color: ${color.white};
     text-decoration: underline;
   }
   @media (max-width: ${breakpoints.sm}) {
      float: none;
      width: 100%;
      margin-bottom: 20px;
    }
`;

const FooterButtons = styled('div')`
  padding: 10px 0;
  .col {
    display: inline-block;
    vertical-align: top;
    width: 45%;
  }
  .col-right {
    width: 54%;
  }
  .line {
    padding: 3px 0 3px 15px;
  }
  .payment-span {
    display: inline-block;
    background: ${color.transparentWhite2};
    height: 50px;
    padding: 1px 0;
    border-radius: 5px;
    margin: 0 10px;
    vertical-align: middle
    ;
  }
  .top-line {
    margin-bottom: 16px;
  }
  .button {
    display: block;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 1rem;
  }
  
   @media (max-width: ${breakpoints.lg}) {
     .col {
       width: 87%;
     }
     .line {
      display: flex;
      margin: 0 0 10px;
      padding: 0;
      justify-content: space-between;
      
    }
    .payment-span {
      flex: 0 0 48%;
      margin: 0;
      text-align: center;
    }
   }
  
  @media (max-width: ${breakpoints.md}) {
    .col {
       width: 100%;
     }
    .payment-span {
      flex: 0 0 49%;
      margin-bottom: 5px;
    }
    .button {
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .col {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .payment-span {
      flex: 0 0 48%;
    }
  }
  
`;

export default () => (
  <FooterBlock>
    <div className="footer-top">
      <Wrapper>
        <div className="top-footer-col top-footer-left">
          <LogoUpd to="/" text="GCLUB-CASINO.com"/>

          <FooterButtons className="footer-buttons">
            <div className="col">
              <ButtonLink to={process.env.LINE_ID} external={true} ariaText="Line" target="_blank"
                          rel="noopener noreferrer"
                          className="button green-button"
                          text={process.env.LINE_ID_TEXT} iconName="line" iconLeft={true} iconSize={26}/>
              <ButtonLink to={'tel:' + `${process.env.PHONE_TO_CALL}`} external={true} ariaText="Call" className="button black-button"
                          text={process.env.PHONE_TO_DISPLAY} iconName="call-answer" iconLeft={true} iconSize={24}/>
            </div>
            <div className="col col-right">
              <div className="line top-line">
                <span className="payment-span">
                  <Visa/>
                </span>
                <span className="payment-span">
                  <Mastercard/>
                </span>
              </div>
              <div className="line bottom-line">
                <span className="payment-span">
                  <Discover/>
                </span>
                  <span className="payment-span">
                <Paypal/>
                </span>
              </div>
            </div>
          </FooterButtons>
          <div className="footer-bottom">
            <Copy>
              <span>{`${process.env.COPY}`}</span>
              <Link to="/sitemap/">Sitemap</Link>
            </Copy>
            <div className="social-buttons">
              <a href={process.env.FACEBOOK_LINK} rel="noreferrer" title="Gclub Facebook" target="_blank">
                <Facebook/>
              </a>
              <a href={process.env.YOUTUBE_LINK} rel="noreferrer" title="Gclub Youtube" target="_blank">
                <Youtube/>
              </a>
              <a href={process.env.TWITTER_LINK} rel="noreferrer" title="Gclub Twitte" target="_blank">
                <Twitter/>
              </a>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="top-footer-col top-footer-right">
          <div className="menu-col">
            <div className="h4">Game Types</div>
            <FooterMenuGameTypes/>
          </div>
          <div className="menu-col menu-col-wide">
            <div className="h4">Game Providers</div>
            <FooterMenuGameProviders/>
          </div>
          {/*
          <div className="menu-col">
            <div className="h4">Blogs</div>
            <FooterMenuBlogs/>
          </div>
          */}
          <div className="menu-col">
            <div className="h4">สมัครสมาชิก</div>
            <FooterMenuRegister/>
          </div>
          <div className="menu-col">
            <div className="h4">Other</div>
            <FooterMenuOther/>
          </div>
        </div>
      </Wrapper>
    </div>
    <Zopim/>
  </FooterBlock>
);
