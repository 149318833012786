import React from 'react';
const Facebook = ({ width = '44', className = '', height = '44', viewBox='0 0 44 44' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M33.26 10.677A15.864 15.864 0 0021.97 6a15.95 15.95 0 00-11.292 4.677A15.864 15.864 0 006 21.97c0 3.325 1.012 6.512 2.927 9.217a15.933 15.933 0 007.487 5.758.505.505 0 00.68-.487v-9.11a.504.504 0 00-.504-.503h-4.202v-3.698h4.202a.504.504 0 00.504-.505v-.672c0-4.388 4.163-8.237 8.91-8.237h2.184v3.698h-2.185c-1.423 0-2.717.41-3.644 1.154-1.025.823-1.567 1.993-1.567 3.385v.672c0 .279.226.504.504.504h1.989a.504.504 0 000-1.008H21.8v-.168c0-2.606 2.264-3.53 4.202-3.53h2.69a.504.504 0 00.504-.504v-4.707a.504.504 0 00-.504-.504h-2.69c-2.465 0-4.964.98-6.855 2.69-1.975 1.785-3.062 4.113-3.062 6.555v.168h-4.203a.504.504 0 00-.504.504v4.707c0 .278.226.504.504.504h4.203v7.875C10.627 33.39 7.009 27.95 7.009 21.97c0-8.25 6.71-14.96 14.96-14.96 8.24 0 14.96 6.72 14.96 14.96 0 8.249-6.711 14.96-14.96 14.96l-.168-.001v-9.076h6.892a.504.504 0 00.504-.504V22.64a.504.504 0 00-.504-.504H25.74a.504.504 0 000 1.008h2.447v3.698h-6.892a.504.504 0 00-.504.505v10.07c0 .27.213.492.482.504A15.864 15.864 0 0033.26 33.26a15.95 15.95 0 004.678-11.292c0-4.266-1.661-8.276-4.677-11.292z" fill="#FFCDCD" fillRule="evenodd"/>
  </svg>;

  export default Facebook;