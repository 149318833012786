import React from 'react';
const Twitter = ({ width = '44', className = '', height = '44', viewBox='0 0 44 44' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M22 6c4.274 0 8.292 1.664 11.314 4.686A15.895 15.895 0 0138 22c0 4.274-1.664 8.292-4.686 11.314A15.895 15.895 0 0122 38a15.895 15.895 0 01-11.314-4.686A15.895 15.895 0 016 22c0-4.274 1.664-8.292 4.686-11.314A15.895 15.895 0 0122 6zm0 1.01C13.735 7.01 7.01 13.736 7.01 22S13.736 36.99 22 36.99 36.99 30.264 36.99 22 30.264 7.01 22 7.01zm4.047 7.074c1.278 0 2.48.47 3.415 1.328a7.274 7.274 0 002.37-.851.506.506 0 01.624.097c.13.14.16.346.098.527a4.604 4.604 0 01-.637 1.214c.272-.091.539-.198.8-.319a.518.518 0 01.555.07c.193.166.23.444.097.653a9.106 9.106 0 01-2.264 2.443 12.034 12.034 0 01-3.102 7.994.51.51 0 01-.762-.677 11.065 11.065 0 002.847-7.022l.007-.353-.004-.181a.503.503 0 01.214-.43 8.16 8.16 0 001.178-1.002 8.926 8.926 0 01-1.235.205.506.506 0 01-.463-.215l-.045-.08-.008-.016a.507.507 0 01.22-.651c.343-.192.648-.435.907-.72-.487.164-.988.282-1.503.353a.5.5 0 01-.424-.147 4.019 4.019 0 00-2.885-1.21 4.053 4.053 0 00-4.048 4.049l.001.088a.512.512 0 01-.527.525l-.248-.01c-.66-.003-2.399-.544-2.415-.55a12.349 12.349 0 01-4.693-2.907 3.629 3.629 0 00-.116.916c0 1.282.653 2.444 1.749 3.112a.52.52 0 01.254.498.506.506 0 01-.487.448 4.612 4.612 0 01-1.19-.119 3.351 3.351 0 002.509 2.704c.247.06.413.297.38.556a.523.523 0 01-.41.432 4.833 4.833 0 01-.84.115 3.469 3.469 0 002.919 1.754.505.505 0 01.481.408.52.52 0 01-.184.495 8.91 8.91 0 01-4.15 1.919c1.255.478 2.596.726 3.953.726 2.422 0 4.668-.783 6.497-2.108a.513.513 0 01.592-.01l.071.06.06.07a.51.51 0 01-.12.703 12.032 12.032 0 01-7.1 2.295 12.11 12.11 0 01-6.15-1.674.52.52 0 01-.255-.527.506.506 0 01.523-.421 7.904 7.904 0 004.497-1.142 4.478 4.478 0 01-2.892-2.924.508.508 0 01.196-.579 4.363 4.363 0 01-1.634-3.412l.004-.154a.506.506 0 01.599-.491 4.626 4.626 0 01-.883-2.734c0-.727.165-1.425.489-2.074a.506.506 0 01.84-.098 11.344 11.344 0 004.817 3.207l.29.087c.43.125 1.16.32 1.58.386a5.066 5.066 0 015.041-4.629z" fill="#FFCDCD" fillRule="evenodd"/>
  </svg>;

  export default Twitter;