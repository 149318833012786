import React from "react";
import styled from "@emotion/styled";
import {breakpoints, color} from "../../utils/style";
import IcomoonIcon from '../shared/IcomoonIcon';

const showHideChat = (id, frameID) => {
  const chatBox = document.getElementById(id);
  let chatIframe = document.getElementById(frameID);
  chatBox.classList.toggle('open');
  if (chatBox.classList.contains('open')) {
    chatIframe.src = process.env.CHAT_IFRAME_SRC;
  } else {
    chatIframe.src = '';
  }
};

const Wrap = styled('div')`
  .live-chat {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background: ${color.orange};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    color: #000;
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
  
  .live-chat-text {
    padding: 5px 10px;
    background: ${color.white};
    margin-top:-12px;
    line-height: 2;
    font-size: 14px;
    font-style: italic;
  }
    
  .live-chat-popup {
    height: 400px;
    width: 300px;
    position:fixed;
    right:4px;
    bottom:0;
    z-index:999;
    overflow:hidden;
    display: none;
      
    iframe {
      position: absolute;
      bottom: 0;
      right: -5px;
      width:1280px;
      height:420px;
      border:0;
    }
  }
    
  &.open {
    bottom: 0;
    .live-chat-popup {
      display: block;
    }
    .live-chat {
      display: none;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
  
`;

export default () => (
  <Wrap id="chat-wrapper">
    <button className="live-chat" id="live-chat" onClick={() => showHideChat('chat-wrapper', 'chat-frame')}>
      <IcomoonIcon icon="chat-2" color={color.trueBlack} size={16}/>
      <span>แชทสด</span>
    </button>
    <div className="live-chat-popup" id="live-chat-popup" >
      <iframe id="chat-frame" className="chat-frame" src="about:blank" />
    </div>
  </Wrap>
);