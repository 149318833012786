import React from 'react';
const Youtube = ({ width = '44', className = '', height = '44', viewBox='0 0 44 44' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M22 6c4.274 0 8.292 1.664 11.314 4.686A15.895 15.895 0 0138 22c0 4.274-1.664 8.292-4.686 11.314A15.895 15.895 0 0122 38a15.895 15.895 0 01-11.314-4.686A15.895 15.895 0 016 22c0-4.274 1.664-8.292 4.686-11.314A15.895 15.895 0 0122 6zm0 1.01C13.735 7.01 7.01 13.736 7.01 22S13.736 36.99 22 36.99 36.99 30.264 36.99 22 30.264 7.01 22 7.01zm-10.074 6.281a88.047 88.047 0 0119.727 0c.83.094 1.495.733 1.615 1.554a49.342 49.342 0 010 14.31 1.838 1.838 0 01-1.615 1.554 87.55 87.55 0 01-3.175.3.506.506 0 01-.537-.418l-.007-.086.007-.087a.507.507 0 01.462-.417 86.584 86.584 0 002.092-.185l1.043-.112.11-.02a.822.822 0 00.616-.675c.681-4.65.681-9.367 0-14.018a.824.824 0 00-.725-.696 86.92 86.92 0 00-19.499 0 .824.824 0 00-.725.696 48.336 48.336 0 000 14.018.824.824 0 00.725.695c4.611.521 9.268.67 13.905.449a.507.507 0 11.051 1.01 87.964 87.964 0 01-14.07-.454 1.838 1.838 0 01-1.615-1.554 49.342 49.342 0 010-14.31c.12-.821.785-1.46 1.615-1.554zm6.526 3.728a.501.501 0 01.396-.053l.096.039 8.683 4.552a.5.5 0 01.077.836l-.077.05-8.683 4.552a.501.501 0 01-.726-.356l-.007-.086v-9.106a.5.5 0 01.241-.428zm.76 1.256v7.45L26.317 22l-7.105-3.725z" fill="#FFCDCD" fillRule="evenodd"/>
  </svg>;

export default Youtube;