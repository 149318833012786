import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import LazyLoad from 'react-lazyload';
import Plx from 'react-plx';
import styled from '@emotion/styled';

const ParallaxContainer = styled('div')`
  position: relative;
  z-index: -100;
  .bg {
    display: block;
    width: 90%;
    position: fixed;
    top: 0;
    opacity: 0.5;
    color: #000;
  }
  .bg-one {
    top: 0;
    left: 70px;
    width: 100%;
    .gatsby-image-wrapper {
      margin-left: -90px;
    }

    @media (min-width: 1600px) {
      left: 5em;
    }
    .gatsby-image-wrapper {
      margin-left: -60px;
    }
  }
  .bg-two {
    right: 0;
    top: 0;
    width: 100%;
    .gatsby-image-wrapper {
      margin-left: -10%;
    }
  }
`;

const ParallaxHome = () => {
  //console.log('Parallax rendering');
  const image1ParallaxData = [
    {
      start: 0,
      duration: 1500,
      properties: [
        {
          startValue: 100,
          endValue: 110,
          property: 'translateY',
        },
      ],
    },
    {
      start: 1001,
      duration: 4000,
      properties: [
        {
          startValue: 100,
          endValue: -350,
          property: 'translateY',
        },
      ],
    },
  ];

  const image2ParallaxData = [
    {
      start: -100,
      duration: 5500,
      properties: [
        {
          startValue: -200,
          endValue: 350,
          property: 'translateY',
        },
      ],
    },
  ];

  return (
    <StaticQuery
      query={graphql`
        query {
          parallax1: file(relativePath: { eq: "parallax/bg_layer_1.png" }) {
            childImageSharp {
              fluid(maxWidth: 1980) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          parallax2: file(relativePath: { eq: "parallax/bg_layer_2.png" }) {
            childImageSharp {
              fluid(maxWidth: 1980) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <LazyLoad>
          <ParallaxContainer>
            <Plx className="bg bg-two" parallaxData={image2ParallaxData}>
              <Img
                alt="GCLUB Background 2"
                fluid={data.parallax2.childImageSharp.fluid}
              />
            </Plx>
            <Plx className="bg bg-one" parallaxData={image1ParallaxData}>
              <Img
                alt="GCLUB Background 1"
                fluid={data.parallax1.childImageSharp.fluid}
              />
            </Plx>
          </ParallaxContainer>
        </LazyLoad>
      )}
    />
  );
};

export default ParallaxHome;
